import React from "react";
import toAbsoluteUrl from "to-absolute-url";
import { Navigate, Link  } from "react-router-dom";
import toast from 'react-hot-toast';

import { login } from "./Api/auth";
import { setSession, onSession } from "../../utils/session";

export default function (props) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const success = () => toast.success('Bienvenido.');
    const error = () => toast.error('Los campos son incorrectos, intentalo de nuevo.');
    const validation = () => toast.error('Todos los campos son requeridos.');

    const doLogin = (event) => {
        event.preventDefault();
        if (email !== '' && password !== '') {
            setLoading(true);
            login(email, password)
                .then((res) => {
                    console.log('login response -> ', res.data);
                    setSession(res.data.access_token);
                    // props.history.push('/');
                    setLoading(false);
                    success();
                })
                .catch((err) => {
                    console.log('login error -> ', err);
                    setLoading(false);
                    error();
                })
        } else {
            console.log('invalid fields');
            validation();
        }
    }

    return (
        <div className="Auth-form-container">
            {onSession()
                ? <Navigate to="/" replace={true} />
                : null}
            <form className="Auth-form" onSubmit={doLogin}>
                <div className="Auth-form-content">
                    <div className="w-100 mt-3 text-center">
                        <img
                            className="logo"
                            src={toAbsoluteUrl('/assets/img/logo.png')}
                            alt="Logo" />
                    </div>
                    <div className="form-group mt-3">
                        <label>Correo electrónico</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="Ingresa el correo"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Contraseña</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Ingresa la contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button
                            type="submit"
                            className="btn">
                            {loading
                                ? <div
                                    className="spinner-border text-light"
                                    role="status"
                                    style={{ height: 20, width: 20 }}>
                                    <span className="sr-only" />
                                </div>
                                : 'Entrar'}
                        </button>
                    </div>
                    <p className="forgot-password text-right mt-2">
                        Olvide mi <Link to={'/recovery'}>contraseña</Link>?
                    </p>
                </div>
            </form>
        </div>
    )
}