import axios from "axios";

import { getSession } from "../../../utils/session";

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const CHANGE_PASSWORD = `${API_URL}/auth/changePassword`

export function changePassword(password, password_confirmation) {
    return axios.post(
        CHANGE_PASSWORD,
        { password, password_confirmation },
        { headers: { Authorization: getSession()} }
    );
}