import React from 'react';
import toAbsoluteUrl from "to-absolute-url";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function Recovery(props) {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const success = () => toast.success('La contraseña de recuperacion ha sido enviada.');
  const error = () => toast.error('El correo electronico no existe.');
  const validation = () => toast.error('El campo de correo electronico es requerido.');

  React.useEffect(() => {
    console.log('props ->', props);
  }, [])
  

  const doRecovery = (event) => {
    event.preventDefault();
    if (email !== '') {
      setLoading(true);
      setTimeout(() => {
        success();
        setLoading(false);
        setEmail('');
        navigate('/login');
      }, 2000);
    } else {
      validation();
    }
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={doRecovery}>
        <div className="Auth-form-content">
          <div className="w-100 mt-3 text-center">
            <img
              className="logo"
              src={toAbsoluteUrl('/assets/img/logo.png')}
              alt="Logo" />
          </div>
          <div className="w-100 mt-3 text-center">
            <h5>Recuperación de contraseña</h5>
          </div>
          <div className="form-group mt-3">
            <label>Correo electrónico</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Ingresa el correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button
              type="submit"
              className="btn">
              {loading
                ? <div
                    className="spinner-border text-light"
                    role="status"
                    style={{ height: 20, width: 20 }}>
                    <span className="sr-only" />
                  </div>
                : 'Enviar'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Recovery;