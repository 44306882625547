import axios from "axios";

import { getSession } from "../../../utils/session";

const API_URL = process.env.REACT_APP_API_URL || 'api';

export const GET_PROFILE = `${API_URL}/auth/profile`;

export function getProfile() {
    return axios.get(
        GET_PROFILE,
        { headers: { Authorization: getSession() } }
    );
}