import { StyleSheet, Font } from '@react-pdf/renderer';

import SourceFont from './Fonts/CenturyGothic.ttf';
import SourceFontBold from './Fonts/CenturyGothicBold.ttf';

// Register font
Font.register({
  family: 'CenturyGothic',
  format: "truetype",
  src: SourceFont,
});

Font.register({
  family: 'CenturyGothicBold',
  format: "truetype",
  src: SourceFontBold
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    fontFamily: 'CenturyGothic',
  },
  content: {
    marginTop: '20px',
    padding: '25px',
    height: 'auto',
    width: '100%',
  },
  container: {
    width: '100%',
    height: 'auto',
    flexDirection: 'row',
  },
  halfContainer: {
    width: '50%',
    height: 'auto',
    flexDirection: 'row',
  },
  rowContainer: {
    width: '100%',
    height: 'auto',
  },
  title: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'CenturyGothicBold',
    width: '60%',
    padding: '10px',
    backgroundColor: '#CE2858',
    color: '#FFFFFF',
    borderTopRightRadius: 18,
    borderBottomRightRadius: 18,
    left: -25,
  },
  subtitle: {
    color: '#1C324B',
    fontFamily: 'CenturyGothicBold',
  },
  logo: {
    width: '100px',
    height: 'auto',
    right: -175,
  },
  periodTitle: {
    marginTop: 20,
    padding: 4,
    color: '#FFFFFF',
    fontFamily: 'CenturyGothicBold',
    backgroundColor: '#CE2858',
    borderRadius: 12,
    textAlign: 'center',
  },
  rowPeriod: {
    width: '100%',
    height: 'auto',
    flexDirection: 'row',
  },
  periodTextTitle1: {
    width: '48%',
    color: '#1C324B',
    padding: 3,
    fontFamily: 'CenturyGothicBold',
    marginRight: 4,
  },
  periodTextValue1: {
    width: '48%',
    padding: 3,
    color: '#1C324B',
    textAlign: 'center',
  },
  periodTextTitle2: {
    width: '48%',
    color: '#1C324B',
    padding: 3,
    fontFamily: 'CenturyGothicBold',
    backgroundColor: '#E5E5E5',
    marginRight: 4,
  },
  periodTextValue2: {
    width: '48%',
    color: '#1C324B',
    padding: 3,
    backgroundColor: '#E5E5E5',
    textAlign: 'center',
  },
  movementsTitle: {
    marginTop: 15,
    fontSize: 16,
    color: '#1C324B',
    width: '100%',
    height: 'auto',
    fontFamily: 'CenturyGothicBold',
    textAlign: 'center',
  },
  movementsSubtitle1: {
    padding: 4,
    marginTop: 10,
    width: '19%',
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#CE2858',
    fontFamily: 'CenturyGothicBold',
    borderRadius: 10
  },
  movementsSubtitle2: {
    padding: 4,
    marginTop: 10,
    width: '39%',
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#CE2858',
    fontFamily: 'CenturyGothicBold',
    borderRadius: 10
  },
  movementsValue1: {
    padding: 4,
    color: '#1C324B',
    textAlign: 'center',
    width: '19%',
  },
  movementsValue2: {
    padding: 4,
    color: '#1C324B',
    textAlign: 'center',
    width: '39%',
  },
  movementsValue3: {
    padding: 4,
    color: '#1C324B',
    textAlign: 'center',
    width: '19%',
    backgroundColor: '#E5E5E5',
  },
  movementsValue4: {
    padding: 4,
    color: '#1C324B',
    textAlign: 'center',
    width: '39%',
    backgroundColor: '#E5E5E5',
  },
  movementsTotal: {
    padding: 4,
    width: '19%',
    height: 'auto',
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: '#1C324B',
    marginRight: 4,
    marginTop: 5,
    fontFamily: 'CenturyGothicBold',
    borderRadius: 15
  },
  footer: {
    position: 'absolute',
    display: 'block',
    height: 'auto',
    width: '100%',
    top: 785,
    textAlign: 'left',
    paddingRight: '25px',
    paddingLeft: '25px',
    fontSize: 8,
  },
  summaryContainer: {
    width: '100%',
    marginTop: '10px',
    paddingLeft: '60px',
    paddingRight: '60px',
  },
  summaryTitle: {
    width: '100%',
    marginTop: '10px',
    color: '#FFFFFF',
    backgroundColor: '#CE2858',
    padding: 4,
    fontFamily: 'CenturyGothicBold',
    borderRadius: 15,
    textAlign: 'center',
  },
  summarySubtitle: {
    color: '#1C324B',
    width: '49%',
    fontFamily: 'CenturyGothicBold',
    marginRight: 4,
  },
  summarySubtitle2: {
    color: '#1C324B',
    width: '49%',
    textAlign: 'center'
  },
  footerContainer: {
    width: '100%',
    height: 'auto',
    paddingLeft: 5,
    borderLeft: '2pt solid #CE2858',
  },
  footerText1: {
    width: '100%',
    color: '#1C324B',
    fontFamily: 'CenturyGothicBold',
  },
  footerText2: {
    width: '100%',
    color: '#1C324B',
  },
});

export default styles;