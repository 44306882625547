import React from 'react';
import AccountState from './AccountState';
import Header from './Header';

import { getProfile } from './Api/profile';
import { getMovements } from './Api/movements';

function Home() {
    const [customerId, setCustomerId] = React.useState(0);
    const [fullName, setFullName] = React.useState('');
    const [reference, setReference] = React.useState('');
    const [accountState, setAccountState] = React.useState(null);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');

    React.useEffect(() => {
        getProfile()
            .then((res) => {
                console.log('res -> ', res);
                setFullName(res.data.name);
                setReference(res.data.reference_number);
                setCustomerId(res.data.id);
                return getMovements(res.data.id);
            })
            .then((res) => {
                console.log('res account -> ', res);
                setAccountState(res.data);
            })
            .catch((err) => {
                console.log('err -> ', err);
            });
    }, [])

    const getMovementsByPeriod = (id) => {
        getMovements(id, startDate, endDate)
            .then((res) => {
                console.log('res account -> ', res);
                setAccountState(res.data);
            })
            .catch((err) => {
                console.log('err -> ', err);
            });
    }
    

    return (
        <>
            <Header />
            <AccountState
                endDate={endDate}
                fullName={fullName}
                reference={reference}
                startDate={startDate}
                customerId={customerId}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                accountState={accountState}
                getMovementsByPeriod={getMovementsByPeriod} />
        </>
    );
}

export default Home;
