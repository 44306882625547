import React from 'react';
import toAbsoluteUrl from "to-absolute-url";
import { exitSession } from '../../utils/session';
import { Navigate } from "react-router-dom";

import toast from 'react-hot-toast';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { changePassword } from './Api/account';

function Header() {
  const [show, setShow] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [checkSession, setCheckSession] = React.useState(false);

  const logout = () => {
    exitSession();
    setCheckSession(true);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const success = () => toast.success('La contraseña se cambio con exito.');
  const error = () => toast.error('Ha ocurrido un error, intentalo más tarde.');
  const validation = () => toast.error('Todos los campos son requeridos.');

  const updatePassword = () => {
    if (password !== '' && passwordConfirmation !== '') {
      changePassword(password, passwordConfirmation)
        .then((res) => {
          console.log('res -> ', res);
          handleClose();
          success();
        })
        .catch((err) => {
          console.log('err -> ', err);
          handleClose();
          error();
        })
    } else {
      validation();
    }
  }

  return (
    <>
      {checkSession
        ? <Navigate to="/login" replace={true} />
        : null}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#63001A"}}>
            Cambiar contraseña
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nueva contraseña
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Confirmar contraseña
                    </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="contraseña"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="change-password-button2"
            onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            className="change-password-button1"
            onClick={() => updatePassword()}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar
        className="shadow-sm p-1 mb-5 rounded"
        collapseOnSelect expand="lg" bg="light">
        <Container>
          <Navbar.Brand href="#home">
            <img
              className="header-logo"
              src={toAbsoluteUrl('/assets/img/logo.png')}
              alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                className="nav-link" href="#" onClick={() => handleShow()}>
                <b>Cambiar contraseña</b>
              </Nav.Link>
              <Nav.Link
                className="nav-link" href="#" onClick={() => logout()}>
                <b>Cerrar sesión</b>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;