const TOKEN_KEY = '@tkn';

export const setSession = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const getSession = () => (
    `bearer ${localStorage.getItem(TOKEN_KEY)}`
);

export const exitSession = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const onSession = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}