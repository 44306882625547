import React from 'react';
import Report from './Report';
import toast from 'react-hot-toast';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AccountState(props) {

  const validation = () => toast.error('Todos los campos son requeridos.');

  React.useEffect(() => {
    console.log('account state -> ', props.accountState);
  }, [])
  

  return (
    <div className="container" style={{ height: '100vh' }}>
        <div className="w-100 mt-4 mb-3 shadow p-3 bg-body rounded">
          <div className="w-100 text-center mb-4" style={{ color: "#63001A"}}>
              <h4>{`Bienvenido, ${props.fullName}`}</h4>
          </div>
          <Form>
            <Container>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="startDateId">
                    <Form.Label>Fecha inicio</Form.Label>
                    <Form.Control
                      type="date"
                      value={props.startDate}
                      onChange={(e) => props.setStartDate(e.target.value)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="endDateId">
                    <Form.Label>Fecha final</Form.Label>
                    <Form.Control
                      type="date"
                      value={props.endDate}
                      onChange={(e) => props.setEndDate(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="w-100 generate-button"
                    onClick={() => {
                      if (props.startDate !== '' && props.endDate !== '') {
                        props.getMovementsByPeriod(props.customerId);
                      } else {
                        validation()
                      }
                    }}>
                    Generar
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
        <div className="w-100 h-100">
          { props.accountState !== null
            ? <Report
                user={props.accountState.customer}
                movements={props.accountState.movements}
                endDate={props.accountState.period.end_date}
                startDate={props.accountState.period.start_date}
                />
            : null }
        </div>
    </div>
  )
}

export default AccountState