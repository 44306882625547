import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import PrivateRoute from './components/PrivateRoute';

import Login from './components/Auth/Login';
import Recovery from './components/Auth/Recovery';
import Home from './components/Home/Home';

function App() {

  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs">
        <div className="App">
          <Toaster position="top-right" />
          <BrowserRouter>
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/recovery" element={<Recovery />} />
                <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path="/" element={<Home />} />
                </Route>
              </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
  );
}

export default App;