import React from 'react';
import dateFormat from 'dateformat';
import moment from 'moment';
import toAbsoluteUrl from "to-absolute-url";
import { PDFViewer, Page, Image, Text, View, Document } from '@react-pdf/renderer';

import styles from './ReportStyle';

// Create Document Component
export default function Report (props) {

  dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab',
      'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado',
    ],
    monthNames: [
      'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  };

  React.useEffect(() => {
    console.log('props account state -> ', props)
  },[]);

  const currencyFormat = (amount) => {
    var formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    });
    return formatter.format(amount);
  };

  const format = (x, y) => {
    const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, (v) => (
        ((v.length > 1 ? '0' : '') + eval(`z.${v.slice(-1)}`)).slice(-2)
    ));
    return y.replace(/(y+)/g, (v) => x.getFullYear().toString().slice(-v.length));
  };

  const setTypeOfMovement = (type) => {
    switch (type) {
    case 'income':
        return 'Ingreso';
    case 'yield':
        return 'Rendimiento';
    default:
        return 'Egreso';
    }
  };

  const getSubTotals = (type) => {
    let total = 0;
    props.movements.forEach((item) => {
        if (item.type === type) {
            total += item.amount;
        }
    });
    return total;
  };

  const getFirstIncome = () => {
    let total = 0;
    props.movements.forEach((item) => {
        if (item.type === 'income' && total === 0) {
            total += item.amount;
        }
    });
    return total;
  };

  const getTotals = () => {
    let total = 0;
    props.movements.forEach((item) => {
        if (item.type === 'income') {
            total += item.amount;
        } else if (item.type === 'egress') {
            total -= item.amount;
        } else {
            total += item.amount;
        }
    });
    return total;
  };

  const cutDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    return `${currentYear}-${currentMonth}-05`;
  };

  return (
    <div className="w-100 h-100">
      <PDFViewer className="w-100 h-100">
        <Document>
          {props.movements.length > 0
            ? <Page size="A4" style={styles.page}>
            <View style={styles.content}>
              <View style={styles.container}>
                <View style={styles.halfContainer}>
                  <View style={styles.rowContainer}>
                    <Text style={styles.title}>
                      ESTADO DE CUENTA
                    </Text>
                    <Text style={{...styles.subtitle, marginTop: '10px'}}>
                      {props.user.name}
                    </Text>
                    <Text style={{...styles.subtitle, marginTop: '2px'}}>
                      {`RFC: ${props.user.rfc}`}
                    </Text>
                    <Text style={{...styles.subtitle, marginTop: '2px'}}>
                      {`Domicilio: ${props.user.street} ${props.user.ext_number}`}
                    </Text>
                    <Text style={{...styles.subtitle, marginTop: '2px'}}>
                      {`Colonia: ${props.user.colony}`}
                    </Text>
                    <Text style={{...styles.subtitle, marginTop: '2px'}}>
                      {`País: México`}
                    </Text>
                    <Text style={{...styles.subtitle, marginTop: '2px'}}>
                      {`C.P.: ${props.user.postal_code}`}
                    </Text>
                  </View>
                </View>
                <View style={styles.halfContainer}>
                  <View style={styles.rowContainer}>
                    <Image
                      style={styles.logo}
                      src="assets/img/invexio.png" />
                    <Text style={styles.periodTitle}>
                      INFORMACIÓN DEL PERIODO
                    </Text>
                    <View style={styles.rowPeriod}>
                      <Text style={styles.periodTextTitle1}>
                        Periodo seleccionado
                      </Text>
                      <Text style={styles.periodTextValue1}>
                        {`${dateFormat(props.startDate, 'dd/mm/yyyy')} - ${dateFormat(props.endDate, 'dd/mm/yyyy')}`}
                      </Text>
                    </View>
                    <View style={styles.rowPeriod}>
                      <Text style={styles.periodTextTitle2}>
                        Fecha de corte
                      </Text>
                      <Text style={styles.periodTextValue2}>
                        {cutDate()}
                      </Text>
                    </View>
                    <View style={styles.rowPeriod}>
                      <Text style={styles.periodTextTitle1}>
                        Número de cuenta
                      </Text>
                      <Text style={styles.periodTextValue1}>
                      {props.user.account_number || '-'}
                      </Text>
                    </View>
                    <View style={styles.rowPeriod}>
                      <Text style={styles.periodTextTitle2}>
                        Número de referencia
                      </Text>
                      <Text style={styles.periodTextValue2}>
                        {props.user.reference_number || '-'}
                      </Text>
                    </View>
                    <View style={styles.rowPeriod}>
                      <Text style={styles.periodTextTitle1}>
                        Número de cliente
                      </Text>
                      <Text style={styles.periodTextValue1}>
                      {props.user.customer_number || '-'}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.container}>
                <Text style={styles.movementsTitle}>
                  MOVIMIENTOS
                </Text>
              </View>
              <View style={styles.container}>
                <Text style={{...styles.movementsSubtitle1, marginRight: 4}}>
                  FECHA
                </Text>
                <Text style={{...styles.movementsSubtitle2, marginRight: 4}}>
                  CONCEPTO
                </Text>
                <Text style={{...styles.movementsSubtitle1, marginRight: 4}}>
                  TIPO
                </Text>
                <Text style={styles.movementsSubtitle1}>
                  MONTO
                </Text>
              </View>
              {props.movements.map((item, index) => (
                <View style={styles.container}>
                  {(index % 2)
                    ? <>
                      <Text style={{...styles.movementsValue1, marginRight: 4}}>
                        {format(new Date(item.date), 'dd/MM/yyyy')}
                      </Text>
                      <Text style={{...styles.movementsValue2, marginRight: 4}}>
                        {item.concept}
                      </Text>
                      <Text style={{...styles.movementsValue1, marginRight: 4}}>
                        {setTypeOfMovement(item.type)}
                      </Text>
                      <Text style={{...styles.movementsValue1}}>
                        {currencyFormat(item.amount)}
                      </Text>
                    </>
                    : <>
                      <Text style={{...styles.movementsValue3, marginRight: 4}}>
                        {format(new Date(item.date), 'dd/MM/yyyy')}
                      </Text>
                      <Text style={{...styles.movementsValue4, marginRight: 4}}>
                        {item.concept}
                      </Text>
                      <Text style={{...styles.movementsValue3, marginRight: 4}}>
                        {setTypeOfMovement(item.type)}
                      </Text>
                      <Text style={{...styles.movementsValue3}}>
                        {currencyFormat(item.amount)}
                      </Text>
                    </>}
                </View>
              ))}
              <View style={styles.container}>
                <Text style={{ width: '79%', height: 'auto', marginRight: 1}}></Text>
                <Text style={styles.movementsTotal}>
                  {currencyFormat(getTotals())}
                </Text>
              </View>
              <View style={styles.container}>
                <View style={styles.summaryContainer}>
                  <Text style={styles.summaryTitle}>
                    RESUMEN DEL MES
                  </Text>
                </View>
              </View>
              <View style={{
                ...styles.container,
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '3px',
              }}>
                <View
                  style={{
                    width: '49%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    fontFamily: 'CenturyGothicBold',
                    marginRight: 4,
                  }}>
                  <Text>Saldo inicial</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    textAlign: 'center',
                  }}>
                  <Text>{currencyFormat(getFirstIncome())}</Text>
                </View>
              </View>
              <View style={{
                ...styles.container,
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '3px',
              }}>
                <View
                  style={{
                    width: '49%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    fontFamily: 'CenturyGothicBold',
                    backgroundColor: '#E5E5E5',
                    marginRight: 4,
                  }}>
                  <Text>Depósitos y abonos</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    textAlign: 'center',
                    backgroundColor: '#E5E5E5',
                  }}>
                  <Text>{currencyFormat(getSubTotals('income'))}</Text>
                </View>
              </View>
              <View style={{
                ...styles.container,
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '3px',
              }}>
                <View
                  style={{
                    width: '49%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    fontFamily: 'CenturyGothicBold',
                    marginRight: 4,
                  }}>
                  <Text>Egresos y cargos</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    textAlign: 'center',
                  }}>
                  <Text>{currencyFormat(getSubTotals('egress'))}</Text>
                </View>
              </View>
              <View style={{
                ...styles.container,
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '3px',
              }}>
                <View
                  style={{
                    width: '49%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    fontFamily: 'CenturyGothicBold',
                    backgroundColor: '#E5E5E5',
                    marginRight: 4,
                  }}>
                  <Text>Rendimientos</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    textAlign: 'center',
                    backgroundColor: '#E5E5E5',
                  }}>
                  <Text>{currencyFormat(getSubTotals('yield'))}</Text>
                </View>
              </View>
              <View style={{
                ...styles.container,
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop: '3px',
              }}>
                <View
                  style={{
                    width: '49%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    fontFamily: 'CenturyGothicBold',
                    marginRight: 4,
                  }}>
                  <Text>Saldo final</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: 2,
                    color: '#1C324B',
                    textAlign: 'center',
                  }}>
                  <Text>{currencyFormat(getTotals())}</Text>
                </View>
              </View>
              <View style={styles.footer}>
                <View style={styles.footerContainer}>
                  <Text style={styles.footerText1}>
                    Av. Empresarios 135 Colonia Puerta de Hierro. C.P. 45116 Zapopan, Jalisco.
                  </Text>
                  <Text style={styles.footerText2}>
                    Horario de Lunes a Viernes de 9:00 a.m. a 5:00 p.m. Tel: 33-1306-3996
                  </Text>
                </View>
              </View>
            </View>
          </Page>
          : null}
        </Document>
      </PDFViewer>
    </div>
  )
};