import axios from "axios";

import { getSession } from "../../../utils/session";

const API_URL = process.env.REACT_APP_API_URL || 'api';

export const GET_ACCOUNT_STATE = `${API_URL}/movements/get`;

export function getMovements(id, start_date = '', end_date = '') {
    return axios.get(
        `${GET_ACCOUNT_STATE}?id=${id}&start_date=${start_date}&end_date=${end_date}`,
        { headers: { Authorization: getSession() } }
    );
}